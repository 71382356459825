:root {
  --0px: 0rem;
  --1px: 0.064rem;
  --3px: 0.188rem;
  --2px: 0.125rem;
  --4px: 0.25rem;
  --5px: 0.313rem;
  --4_5px: 0.281rem;
  --5_5px: 0.344rem;
  --6px: 0.375rem;
  --6_5px: 0.406rem;
  --7px: 0.438rem;
  --8px: 0.5rem;
  --9px: 0.563rem;
  --10px: 0.625rem;
  --11px: 0.688rem;
  --12px: 0.75rem;
  --13px: 0.813rem;
  --14px: 0.875rem;
  --15px: 0.938rem;
  --16px: 1rem;
  --18px: 1.125rem;
  --19px: 1.188rem;
  --20px: 1.25rem;
  --22px: 1.375rem;
  --23px: 1.438rem;
  --24px: 1.5rem;
  --25px: 1.563rem;
  --26px: 1.625rem;
  --28px: 1.75rem;
  --30px: 1.875rem;
  --32px: 2rem;
  --34px: 2.125rem;
  --35px: 2.188rem;
  --36px: 2.25rem;
  --40px: 2.5rem;
  --42px: 2.625rem;
  --44px: 2.75rem;
  --46px: 2.875rem;
  --47px: 2.938rem;
  --48px: 3rem;
  --49px: 3.063rem;
  --50px: 3.125rem;
  --52px: 3.25rem;
  --56px: 3.5rem;
  --57px: 3.563rem;
  --60px: 3.75rem;
  --62px: 3.875rem;
  --64px: 4rem;
  --66px: 4.125rem;
  --69px: 4.313rem;
  --70px: 4.375rem;
  --71px: 4.438rem;
  --72px: 4.5rem;
  --74px: 4.625rem;
  --76px: 4.75rem;
  --78px: 4.875rem;
  --79px: 4.938rem;
  --80px: 5rem;
  --88px: 5.5rem;
  --89px: 5.563rem;
  --90px: 5.625rem;
  --100px: 6.25rem;
  --102px: 6.375rem;
  --110px: 6.875rem;
  --120px: 7.5rem;
  --124px: 7.75rem;
  --140px: 8.75rem;
  --144px: 9rem;
  --150px: 9.375rem;
  --158px: 9.875rem;
  --159px: 9.938rem;
  --n-150px: -9.375rem;
  --164px: 10.25rem;
  --180px: 11.25rem;
  --184px: 11.5rem;
  --187px: 11.688rem;
  --200px: 12.5rem;
  --210px: 13.125rem;
  --218px: 13.625rem;
  --220px: 13.75rem;
  --222px: 13.875rem;
  --228px: 14.25rem;
  --240px: 15rem;
  --262px: 16.375rem;
  --295px: 18.438rem;
  --300px: 18.75rem;
  --305px: 19.063rem;
  --312px: 19.5rem;
  --337px: 21.063rem;
  --352px: 22rem;
  --353px: 22.063rem;
  --368px: 23rem;
  --391px: 24.438rem;
  --400px: 25rem;
  --428px: 26.75rem;
  --440px: 27.5rem;
  --480px: 30rem;
  --492px: 30.75rem;
  --500px: 31.25rem;
  --504px: 31.5rem;
  --510px: 31.875rem;
  --512px: 32rem;
  --528px: 33rem;
  --540px: 33.75rem;
  --517px: 32.313rem;
  --560px: 35rem;
  --589px: 36.813rem;
  --590px: 36.875rem;
  --600px: 37.5rem;
  --720px: 45rem;
  --812px: 50.75rem;
  --900px: 56.25rem;
  --1200px: 75rem;
  --1280px: 80rem;
  --1440px: 90rem;
  --1488px: 93rem;
}
