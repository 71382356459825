.points {
  display: flex;
  flex-direction: column;
  padding: 0 var(--24px);

  h1 {
    @include XL2Medium;
    margin-top: var(--24px);
    margin-bottom: var(--24px);
  }

  .points-card {
    display: flex;
    flex-direction: column;
    gap: var(--24px);

    .total-points-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .total-points {
        display: flex;
        align-items: baseline;
        gap: var(--14px);

        .big-points {
          @include MegaRegular;
          color: var(--mp-primary);
        }

        .points-label {
          @include LRegular;
          color: var(--black-alpha-50);
        }
      }

      .points-row-details {
        @include XLMedium;
        color: var(--mp-primary);
        max-width: 555px;
      }
    }

    .user-points {
      display: flex;
      align-items: center;
      width: 100%;
      gap: var(--24px);

      .rec {
        display: flex;
        flex-direction: column;
        gap: var(--8px);
        border-radius: var(--2px);

        .ref-multi {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: var(--16px);

          width: 100%;
          height: var(--64px);
          border: 1px solid var(--u-green);

          p {
            @include XL3Medium;
            color: var(--u-green);
          }
        }

        &.large {
          width: 100%;
          background-color: var(--mp-primary);
          padding: var(--24px);

          .rec-dp-content {
            display: flex;
            align-items: center;
            gap: var(--16px);

            h3 {
              @include XL3Medium;
              color: var(--white);
              max-width: 166px;
            }

            .prog-data-rows {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: var(--16px);
              .top-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .your-points {
                  display: flex;
                  align-items: center;
                  color: var(--white);
                  gap: var(--8px);

                  .yp-label {
                    @include XL2Medium;
                  }
                  .yp-value {
                    @include XL3Medium;
                  }
                }
                .next-tier {
                  display: flex;
                  align-items: center;
                  gap: var(--8px);

                  .nt-label {
                    @include LMedium;
                    color: var(--white);
                  }
                }
              }

              .progress-container {
                width: 100%;
                position: relative;

                .progress-label {
                  position: absolute;
                  top: 1.5rem;
                  transform: translateX(-50%);
                  color: var(--u-green);
                  @include LMedium;
                }

                progress {
                  width: 100%;
                  height: var(--7px);
                }

                progress[value]::-webkit-progress-bar {
                  background-color: var(--white);
                }

                progress[value]::-webkit-progress-value {
                  background-color: var(--u-green);
                }
              }

              .bot-row {
                display: flex;
                justify-content: flex-end;
                width: 100%;

                .tier-at {
                  @include LMedium;
                  color: var(--white-alpha-75);
                }
              }
            }
          }
        }
      }
    }

    .referral-card {
      display: flex;
      flex-direction: column;
      gap: var(--24px);
      padding: var(--30px) var(--20px);
      box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: var(--2px);

      h3 {
        @include XL2Medium;
      }

      .link-row {
        display: flex;
        align-items: center;
        padding: var(--10px) var(--16px);
        justify-content: space-between;
        background-color: var(--black-alpha-03);

        .link-row-left {
          display: flex;
          align-items: center;
          gap: var(--24px);

          .ten-percent {
            position: relative;
            display: inline-block;

            img {
              display: block;
              width: 100%;
              height: auto;
            }

            p {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              margin: 0;
              color: var(--white);
              @include XLMedium;
              text-align: center;
            }
          }

          .link-row-info {
            @include XLMedium;

            .orange {
              color: var(--u-orange);
            }
          }
        }
        .copy-link {
          @include MMedium;
          background-color: var(--sp-primary-06);
          color: var(--mp-primary);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4.5px 8px 4.5px 12px !important;
          width: 180px;
        }

        @keyframes buttonClicked {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(0.95);
          }
          100% {
            transform: scale(1);
          }
        }

        .copy-link:active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          animation: buttonClicked 0.2s ease;
        }
      }

      .referral-points {
        display: flex;
        align-items: center;
        gap: var(--48px);
      }

      .referee-list {
        display: flex;
        flex-direction: column;

        .referee-cols {
          margin-bottom: var(--8px);
          display: flex;
          align-items: center;

          p {
            min-width: var(--220px);

            @include SRegular;
            color: var(--black-alpha-50);
          }
        }

        .referee-row {
          padding: var(--14px) 0;
          display: flex;
          align-items: center;
          p {
            min-width: var(--220px);

            @include SRegular;
            color: var(--black);
          }
        }
      }
    }

    .points-captions {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .points-caption {
        @include MMedium;
      }
    }

    .leaderboard {
      display: flex;
      flex-direction: column;
      padding: var(--30px) var(--20px);
      gap: var(--24px);
      border-radius: var(--2px);
      box-shadow: 0px 10px 40px 0px var(--black-alpha-10);
      background-color: var(--white);

      h2 {
        @include XL2Medium;
      }

      .global-rank-card {
        padding: var(--24px);
        background-color: var(--black-alpha-03);
        display: flex;

        width: 100%;
        justify-content: space-between;

        .daily-tiers {
          display: flex;
          flex-direction: column;
          width: 50%;
          .dt-label {
            @include LMedium;
            color: var(--black-alpha-50);
          }

          .tier-display {
            display: flex;
            flex-direction: column;
            margin-top: var(--18px);
            gap: var(--16px);

            .tier-row {
              display: flex;
              align-items: center;
              gap: var(--16px);
              @include XLMedium;

              &.is-user {
                .tier-label,
                .tier-range {
                  @include XL3Medium;
                }
              }

              .tier-label {
                color: var(--mp-primary);
                min-width: 71px;
                display: flex;
                align-items: center;
                gap: var(--16px);

                .me {
                  background-color: var(--mp-primary);
                  color: var(--white);
                  @include XSRegular;
                  border-radius: 9999rem;
                  padding: var(--4px) var(--3px) var(--5px) var(--3px);
                }
              }

              .tier-range {
                color: var(--black-alpha-20);
              }
            }
          }
        }

        .rank {
          display: flex;
          gap: var(--74px);

          .user-rank {
            display: flex;
            flex-direction: column;
            gap: var(--11px);
            width: 50%;

            .rank-label {
              @include LMedium;
              color: var(--black-alpha-50);
            }

            .rank-value {
              @include XL3Medium;
              color: var(--mp-primary);
            }
          }

          .rank-details {
            @include XL3Regular;
            color: var(--black-alpha-50);
            max-width: var(--391px);
          }
        }

        .median-chart {
          width: 50%;
        }
      }

      .points-list {
        display: flex;
        width: 100%;
        flex-direction: column;

        .points-row {
          display: flex;
          width: 100%;
          padding: var(--14px) 0;

          &.column-labels {
            margin-bottom: var(--19px);
            p {
              color: var(--black-alpha-50);
            }
          }

          &.user-row {
            background-color: var(--sp-primary-06);

            .me {
              visibility: visible;
              background-color: var(--mp-primary);
              color: var(--white);
              @include XSRegular;
              border-radius: 9999rem;
              padding: var(--4px) var(--3px) var(--5px) var(--3px);
            }
            p {
              @include XLMedium;
            }

            .ranking {
              color: var(--mp-primary);
              display: flex;
              align-items: center;
              gap: var(--16px);

              .me {
                visibility: hiddien;
              }
            }
          }

          p {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            @include SRegular;
            color: var(--black-alpha-85);

            // Target the second p element
            &:nth-child(2) {
              flex: 0 0 33.33333%;
              max-width: 33.33333%;
            }
          }
        }
      }
    }
  }
}

.coin {
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  border-radius: 9999rem;
  background: conic-gradient(
    from 169deg at 50% 50%,
    #848484 0deg,
    #e3e3e3 95.39999485015869deg,
    #848484 156.60000085830688deg,
    #e3e3e3 270deg,
    #848484 338.3999991416931deg
  );

  &.smush {
    min-height: var(--22px);
    height: initial;
    width: initial;
    min-width: var(--32px);
    padding: var(--4px) var(--16px);
    @include XLMedium;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .multi {
    @include DisplaySemiBold;
    color: var(--black);
  }

  .tier {
    @include LMedium;
    color: var(--black);
  }
}

.link-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--16px);
  width: var(--560px);
  padding: var(--16px);

  h3 {
    @include XL3Medium;
  }

  p {
    @include LMedium;
    text-align: center;
  }

  a {
    color: var(--mp-primary);
    text-align: center;
    margin-top: -1rem;
  }
}

.do-refer-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--32px);
  width: var(--480px);
  padding: var(--24px);

  h3 {
    @include XL2Medium;
    text-align: center;
  }

  .message {
    @include LMedium;
    text-align: center;
  }

  .code {
    @include LMedium;
    text-align: center;
    padding: var(--8px) var(--16px);
    border-radius: var(--2px);
    background-color: var(--black-alpha-02);
    border: 1px solid var(--black-alpha-10);
    color: var(--black-alpha-50);
  }

  a {
    color: var(--mp-primary);
    text-align: center;
    margin-top: -1rem;
  }
}

.flex-link {
  display: flex;
  align-items: center;
  gap: var(--8px);

  .p-total {
    color: var(--mp-primary);
  }

  .new {
    background-color: var(--mp-primary);
    color: var(--white);
    padding: 0 var(--4px);
    vertical-align: middle;
    border-radius: 9999rem;
  }
}
