.account-widget {
  height: fit-content;
  min-height: var(--480px);
  width: var(--720px);
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include mobile-style {
    width: 100%;
    display: flex;
    min-height: fit-content;
  }

  .left {
    @include mobile-style {
      display: none;
    }
    background: radial-gradient(ellipse 150% 70% at bottom left, #f8d24a 5.79%, #000 100%);
    height: 100%;
    width: var(--240px);
    padding: var(--20px);
    display: flex;
    flex-direction: column;
    gap: var(--24px);
    color: var(--white);
    text-align: center;
    align-items: center;
    justify-content: center;

    .sso {
      height: var(--46px);
      width: var(--140px);
    }

    h1 {
      @include XL3Medium;
    }

    h2 {
      @include MMedium;
      color: var(--white);
    }

    p {
      @include MRegular;
      color: var(--white);
    }
  }

  .right {
    @include mobile-style {
      width: 100%;
    }
    height: 100%;
    width: var(--480px);
    padding: var(--20px) var(--20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: var(--10px);
    position: relative;

    .back {
      position: absolute;
      top: var(--0px);
      left: var(--0px);
      padding: var(--14px) var(--12px);
    }

    h3 {
      @include XLMedium;
      text-align: center;
      color: var(--white);
    }

    h4 {
      @include MRegular;
      color: var(--white);
      // margin-top: var(--16px);
      text-align: center;
    }

    .funding-wallet-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--16px);
      // height: var(--104px);
      width: var(--210px);
      border: 1px solid var(--white-alpha-10);
      border-radius: var(--12px);
      padding: var(--40px, 0);
      cursor: pointer;

      @include mobile-style {
        width: 100%;
        padding: var(--12px) 0;
        height: fit-content;
      }

      &.disabled {
        @include mobile-style {
          cursor: initial;
          opacity: 1;
        }
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
          border: 1px solid var(--white-alpha-10);
        }
      }

      .wallet-icon {
        height: var(--48px);
        width: var(--48px);
      }

      &:hover {
        border: 1px solid var(--white);
      }

      &.full {
        width: 100%;
        height: var(--144px);
        display: flex;
        align-items: center;
        flex-direction: row;
        // margin-top: var(--32px);

        &:hover {
          border: 1px solid var(--white);
          cursor: default;
        }

        .connect-icon {
          height: var(--32px);
          width: var(--32px);
          // margin-right: var(--24px);
          margin-left: auto;
          transform: translateX(-24px);
        }

        &.connected {
          border: 1px solid var(--u-green);
          background-color: var(--u-green-10);
          cursor: default;
        }
      }

      img {
        height: var(--64px);
        width: var(--64px);
      }

      h3 {
        @include LMedium;
      }

      .lined {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: var(--16px);
        width: 100%;
      }
    }
  }

  .account-wallets-outer {
    display: flex;
    height: 100%;
    width: 100%;
    margin-top: var(--32px);
    flex-direction: column;
    gap: var(--24px);
    .account-wallet-list {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: var(--20px);
      width: 100%;

      .account-wallet-select {
        @include mobile-style {
          padding: 0;
        }
        cursor: pointer;
        padding: var(--10px) 0;
        border-bottom: var(--1px) solid var(--black-alpha-10);
        flex: 1 1 calc(50% - var(--20px));

        &:hover {
          border-bottom: var(--1px) solid var(--mp-primary);
        }
      }
    }
  }
}
