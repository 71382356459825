.markets-page {
  height: fit-content;
  width: 100%;
  padding: 0 var(--24px);
  h1 {
    @include XL2Medium;
    margin-top: var(--24px);
    margin-bottom: var(--24px);
  }

  .two-line-container {
    padding: var(--16px);
    border: 1px solid var(--white-alpha-10);
  }
  .chart-cards {
    display: flex;
    align-items: center;
    width: 100%;
    gap: var(--20px);
    margin-top: var(--32px);

    @include mobile-style {
      flex-direction: column;
      gap: var(--16px);
    }
  }

  .markets {
    min-height: 16rem;
    margin-top: var(--32px);
    width: 100%;

    .market-columns {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-evenly;

      .market-column {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        .heading {
          @include MRegular;
          color: var(--white-alpha-50);
        }
        .point {
          @include LMedium;
        }
      }
    }

    .market-rows {
      display: flex;
      flex-direction: column;
      gap: var(--16px);
      margin-top: var(--24px);
      .market-row {
        display: flex;
        align-items: center;
        width: 100%;
        height: var(--48px);

        margin-left: var(--8px);

        &:hover {
          background-color: var(--black-alpha-02);
          .tip-box {
            &.c-row {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
