.ml-10 {
  margin-left: var(--10px);
}

.mt-10 {
  margin-top: var(--10px);
}

.is-flex {
  display: flex;
  align-items: center;

  &.spread {
    width: 100%;
    justify-content: space-between;
  }
}

.clickable {
  cursor: pointer;
}

.center-text {
  text-align: center;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: var(--white-alpha-10);
}

.hr-global-margin {
  height: 1px;
  width: 100%;
  background-color: var(--white-alpha-10);
  margin-top: var(--16px);
  margin-bottom: var(--16px);
}
