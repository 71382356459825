.tx-form {
  background-color: var(--bg-solid-2);
  padding: var(--20px);
  display: flex;
  flex-direction: column;
  gap: var(--20px);
  min-height: fit-content;
  width: var(--480px);
  align-items: center;
  position: static;
  border-radius: var(--2px);
  box-shadow: var(--0px) var(--10px) var(--40px) var(--black-alpha-10);

  &.yellow-zone {
    input {
      color: var(--u-orange) !important;
    }
    .send-btn {
      background-color: var(--u-orange);
      color: var(--white);
    }
  }

  &.red-zone {
    input {
      color: var(--u-red) !important;
    }
    .send-btn {
      background-color: var(--u-red-20);
      color: var(--white);
    }
  }

  &.green-zone {
    input {
      color: var(--u-green) !important;
    }
    .send-btn {
      background-color: var(--u-green);
      color: var(--white);
    }
  }

  .m-title {
    @include XLMedium;
    text-align: center;
  }

  .title {
    display: flex;
    align-items: center;

    .info-point {
      align-items: center;
    }

    .logo {
      margin-right: var(--16px);
      height: var(--30px);
      width: var(--30px);
    }
  }

  .amount-box {
    padding: var(--24px) 0px;
    width: 100%;
  }

  .review-buttons {
    display: flex;
    align-items: center;
    gap: var(--16px);
    width: 100%;

    button {
      width: 100%;
    }
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--0px);
    border-radius: var(--2px);
    width: 100%;
    height: fit-content;
    margin-bottom: var(--4px);
    margin-top: var(--4px);
    background-color: transparent;

    img {
      height: var(--40px);
      width: var(--40px);
    }

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      height: calc(100% - var(--8px));

      .i-label {
        @include XLMedium;
        color: var(--white-alpha-50);
        transform: translateY(var(--4px));

        .dark {
          color: var(--white);
        }

        .error {
          color: var(--u-red);
        }
      }

      .form-input {
        @include MegaRegular;
        color: var(--white);
        width: 95%;
        text-align: center;
        background-color: transparent;

        &.small {
          font-size: var(--44px);
        }

        &.x-small {
          font-size: var(--32px);
        }

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: var(--white-alpha-20);
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: var(--white-alpha-20);
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: var(--white-alpha-20);
        }
      }

      .value {
        @include LRegular;
        color: var(--white-alpha-50);
        transform: translateY(calc(var(--4px) * -1));
      }
    }
  }

  .balances {
    color: var(--white-alpha-50);
    .dark {
      @include LMedium;
    }
    .light {
      @include LRegular;
    }
  }

  .t_c {
    @include SRegular;
    color: var(--white-alpha-50);
  }
}

.slider-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  P {
    @include LMedium;
  }
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: var(--5px);
  background: var(--black-alpha-03);
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
  width: 100%;
  margin-bottom: var(--32px);

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--16px);
    height: var(--16px);
    background: var(--black);
    cursor: pointer;
    border-radius: 9999rem;
  }

  &::-moz-range-thumb {
    width: var(--16px);
    height: var(--16px);
    background: var(--black);
    cursor: pointer;
    border-radius: 9999rem;
  }
}

.slider-value {
  position: absolute;
  top: var(--40px);
  transform: translateX(-50%);
  font-weight: bold;
  background: transparent;
  @include LMedium;
}
