.go2072408551 {
  max-width: fit-content !important;
  background-color: var(--bg-solid-2) !important;
  color: var(--mp-primary) !important;
}

.toast-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--black-alpha-85);

  padding-right: 1rem;
  overflow-wrap: break-word;
  a {
    text-decoration: underline;
  }
}

.toast {
  div {
    width: 18rem;
    background-color: var(--black-alpha-85);
  }
}
